<template>
  <div class="home" ref="outsideWrap" @mousewheel="ONMouseWheel($event)">
    <div class="setting-container">
      <div class="top">
        <h2>视频模拟器</h2>
        <div class="upload-video-wrapper">
          <Upload
            name="file"
            :multiple="false"
            action
            :showUploadList="false"
            :customRequest="() => {}"
            @change="handleChange"
          >
            <p class="ant-upload-text">
              点击或者拖拽视频/图片
              <br />到这个区域上传
            </p>
          </Upload>

          <div
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"
            class="preview-video"
            v-if="videoUrl"
          >
            <img class="preview" :src="videoUrl" v-if="isImage" />
            <video class="preview" v-else :src="videoUrl"></video>
            <div v-if="showMask" class="delete-wrapper">
              <DeleteOutlined
                @click="deleteVideo"
                style="color: #fff; cursor: pointer"
              />
            </div>
          </div>
        </div>
        <div class="mode-wrapper" style="margin: 0 16px">
          <span class="text">设计稿尺寸</span>
          <Select
            v-model:value="designSizeValue"
            style="width: 260px"
            :options="designSizeOption"
          ></Select>
        </div>
      </div>
      <div class="bottom">
        <div class="mode-wrapper" style="margin: 0 16px">
          <span class="text">高度适配模式</span>
          <Select
            v-model:value="ratioValue"
            style="width: 220px"
            :options="fitOptions"
          ></Select>
        </div>
        <div class="mode-wrapper" style="margin: 0 16px">
          <span class="text">图片/视频适配模式</span>
          <Select
            v-model:value="fitValue"
            style="width: 220px"
            :options="coverOptions"
          ></Select>
        </div>
        <div class="mode-wrapper" style="margin-right: 16px">
          <span class="text">底部按钮高度</span>
          <InputNumber v-model:value="textHeight" style="width: 80px" />px
        </div>

        <div class="mode-wrapper" style="margin-right: 16px">
          <span class="text">遮罩视频高度</span>
          <InputNumber v-model:value="maskHeight" style="width: 80px" />px
        </div>
        <div v-if="fitValue === 'cover'" class="mode-wrapper">
          <span class="text">显示超出部分</span>
          <Switch v-model:checked="showFullVideo" />
        </div>
      </div>
      <div class="recommand" style="margin-top: 16px" v-if="reRatio">
        视频/图片整体尺寸推荐大小
        {{ reRatio.recommandVideoInfo.width }}*{{
          reRatio.recommandVideoInfo.height
        }}&nbsp; 宽高比
        {{
          (
            reRatio.recommandVideoInfo.width / reRatio.recommandVideoInfo.height
          ).toFixed(2)
        }}&nbsp;&nbsp; 视频/图片主要内容推荐大小：{{
          reRatio.recommandVideoContentInfo.width
        }}*{{ reRatio.recommandVideoContentInfo.height }}&nbsp;宽高比
        {{
          (
            reRatio.recommandVideoContentInfo.width /
            reRatio.recommandVideoContentInfo.height
          ).toFixed(2)
        }}
      </div>
    </div>

    <div class="simulator-container">
      <div v-if="videoUrl" class="simulator-wrapper">
        <div
          v-for="(item, index) in simulatorList"
          :key="item.id"
          :style="{
            width: item.bg_size.width * scaleValue + 'px',
            padding: '0 48px',
            boxSizing: 'content-box',
          }"
        >
          <SimulatorItem
            :item="item"
            :index="index"
            :videoInfo="videoInfo"
            :isImage="isImage"
            :videoUrl="videoUrl"
            :designInfo="designInfo"
            :ratioValue="ratioValue"
            :textHeight="textHeight"
            :maskHeight="maskHeight"
            :fitValue="fitValue"
            :scaleValue="scaleValue"
            :showFullVideo="showFullVideo"
            :ref="`simulator${item.id}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Upload, InputNumber, Switch, message, Select } from "ant-design-vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
// import VueDraggableResizable from "@/components/DrageResize/vue-draggable-resizable.vue";
// import "@/components/DrageResize/vue-draggable-resizable.css";
import SimulatorItem from "@/components/SimulatorItem/SimulatorItem.vue";
import { minBy, maxBy } from "lodash";

let vm = null;
export default {
  name: "HomeView",
  components: {
    Upload: Upload.Dragger,
    Select,
    SimulatorItem,
    DeleteOutlined,
    InputNumber,
    // VueDraggableResizable,
    Switch,
  },
  data() {
    return {
      fileList: [],
      videoUrl: require("@/assets/imgs/333.png"),

      modeValue: "full",
      showMask: false,
      showFullVideo: false,
      fitValue: "cover",
      ratioValue: "min",
      videoInfo: {},
      designSizeValue: 1,
      videoInfoList: [],
      designSizeOption: [
        {
          sizeInfo: {
            width: 393,
            height: 852,
          },
          value: 1,
          label: "iPhone15 Pro 393*852",
        },
        {
          sizeInfo: {
            width: 390,
            height: 844,
          },
          value: 2,
          label: "iPhone13 390*844",
        },
        {
          sizeInfo: {
            width: 428,
            height: 928,
          },
          value: 3,
          label: "iPhone13 Pro max 428*928",
        },
        {
          sizeInfo: {
            width: 430,
            height: 932,
          },
          value: 4,
          label: "iPhone15 Pro max 430*932",
        },
        {
          sizeInfo: {
            width: 375,
            height: 812,
          },
          value: 5,
          label: "iPhone13 mini 375*812",
        },
      ],
      coverOptions: [
        {
          value: "cover",
          label: "fill(部分内容可能被剪切)",
        },
        {
          value: "contain",
          label: "fit(内容被缩放)",
        },
      ],
      fitOptions: [
        {
          value: "width",
          label: "宽度系数",
        },
        {
          value: "height",
          label: "高度系数",
        },
        {
          value: "min",
          label: "宽高系数最小值",
        },
        {
          value: 1,
          label: "高度不变",
        },
      ],
      scaleValue: 0.5,
      videoHeightRadio: 60,
      videoWidthRadio: 100,
      videoLeft: 0,
      videoTop: 0,
      textHeight: 187,
      maskHeight: 140,
      reRatio: null,
      simulatorList: [
        {
          id: "iPhone_15_Pro_393*852",
          mechine_name: "iPhone15 Pro",
          bg_size: {
            width: 473,
            height: 932,
          },
          content_size: {
            width: 393,
            height: 852,
          },
          bg_src: require("@/assets/imgs/iPhone_15_Pro_393*852.png"),
        },
        {
          id: "iPhone_SE_375*667",
          mechine_name: "iPhone SE",
          no_content_radius: true,
          bg_size: {
            width: 435,
            height: 891,
          },
          content_size: {
            width: 375,
            height: 668,
          },
          bg_src: require("@/assets/imgs/iPhone_SE_375*667.png"),
        },
        {
          id: "iPhone_13_390*844",
          mechine_name: "iPhone13",
          bg_size: {
            width: 470,
            height: 900,
          },
          content_size: {
            width: 390,
            height: 844,
          },
          bg_src: require("@/assets/imgs/iPhone_13_390*844.png"),
        },
        {
          id: "iPhone_13_375*812",
          mechine_name: "iPhone13 mini",

          bg_size: {
            width: 455,
            height: 885,
          },
          content_size: {
            width: 375,
            height: 812,
          },
          bg_src: require("@/assets/imgs/iPhone 11 Pro 375 812.png"),
        },

        {
          id: "iPhone13_Pro_Max_428*926",
          mechine_name: "iPhone13 Pro Max",
          bg_size: {
            width: 500,
            height: 1000,
          },
          content_size: {
            width: 428,
            height: 928,
          },
          bg_src: require("@/assets/imgs/iPhone13_Pro_Max_428*926.png"),
        },
        {
          id: "iphone15_pro_max",
          mechine_name: "iPhone15 Pro Max",
          bg_size: {
            width: 510,
            height: 1012,
          },
          content_size: {
            width: 430,
            height: 932,
          },
          bg_src: require("@/assets/imgs/iPhone15_Pro_Max_430*932.png"),
          video_wrapper_radius: "32px",
        },
        {
          id: "ipad11_pro",
          mechine_name: "iPad11 Pro",

          bg_size: {
            width: 946,
            height: 1306,
          },
          content_size: {
            width: 834,
            height: 1194,
          },
          bg_src: require("@/assets/imgs/iPad_Pro11_834*1194.png"),
        },
        {
          id: "iPad_810*1080",
          no_content_radius: true,
          mechine_name: "iPad",
          bg_size: {
            width: 922,
            height: 1332,
          },
          content_size: {
            width: 810,
            height: 1080,
          },
          bg_src: require("@/assets/imgs/iPad_810*1080.png"),
        },
      ],
      isImage: true,
    };
  },
  computed: {
    designInfo() {
      return this.designSizeOption.find(
        (item) => item.value === this.designSizeValue
      ).sizeInfo;
    },
  },
  watch: {
    videoInfo: function (val) {
      if (val && JSON.stringify(val) !== "{}") {
        this.calcRecommandRatio();
      } else {
        this.reRatio = null;
      }
    },
    textHeight() {
      this.calcRecommandRatio();
    },
    maskHeight() {
      this.calcRecommandRatio();
    },
    designSizeValue() {
      this.calcRecommandRatio();
    },
    fitValue() {
      this.calcRecommandRatio();
    },
  },
  methods: {
    getComputedDrageY(wrapper_height) {
      const videoHeight = (wrapper_height * this.videoHeightRadio) / 100;
      const top = (wrapper_height * this.videoTop) / 100;
      const y =
        wrapper_height - videoHeight - top < 10
          ? wrapper_height - videoHeight
          : top;
      return Math.ceil(y);
    },
    getComputedVideoStyle(wrapper_width, wrapper_height) {
      const { Width, Height } = this.videoInfo;
      if (!Width || !Height) {
        return {};
      }

      if (wrapper_width / wrapper_height < Width / Height) {
        return {
          width: "auto",
          height: "100%",
          top: 0,
          left: -((wrapper_height / Height) * Width - wrapper_width) / 2 + "px",
        };
      } else {
        return {
          width: "100%",
          height: "auto",
          top: -((wrapper_width / Width) * Height - wrapper_height) / 2 + "px",
          left: 0,
        };
      }
    },

    onResize: function (x, y, width, height, originWidth, originHeight) {
      this.videoLeft = ((x / originWidth) * 100).toFixed(2);
      this.videoTop = ((y / originHeight) * 100).toFixed(2);
      const videoWidth = ((width / originWidth) * 100).toFixed(2);
      this.videoWidthRadio = videoWidth > 99 ? 100 : videoWidth;
      const videoHeight = ((height / originHeight) * 100).toFixed(2);
      this.videoHeightRadio = videoHeight > 99 ? 100 : videoHeight;
    },
    onDrag: function (x, y, originWidth, originHeight) {
      const calc_left = ((x / originWidth) * 100).toFixed(2);
      this.videoLeft = calc_left < 0 ? 0 : calc_left;
      this.videoTop = ((y / originHeight) * 100).toFixed(2);
    },
    // widthChange(value) {
    //   this.videoLeft = (100 - value) / 2;
    // },
    getImageInfo() {
      let image = new Image();
      image.onload = function () {
        vm.videoInfo = {
          Width: image.width,
          Height: image.height,
        };
      };
      image.src = this.videoUrl;
    },
    handleChange(info) {
      this.fileList = [info.file];
      this.videoUrl = URL.createObjectURL(info.file.originFileObj);
      const reg = /(\.jpg|\.jpeg|\.JPG|\.JPEG|\.PNG|\.png)$/g;
      this.isImage = reg.test(info.file.originFileObj.name);
      if (this.isImage) {
        this.getImageInfo();
      } else {
        if (!window.MediaInfo) {
          message.error("网络不好，js加载失败，请稍后再试");
          return;
        }
        window.MediaInfo.mediaInfoFactory({ format: "object" }, (mediainfo) => {
          const getSize = () => info.file.originFileObj.size;
          const readChunk = (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target.error) {
                  reject(event.target.error);
                }
                resolve(new Uint8Array(event.target.result));
              };
              reader.readAsArrayBuffer(
                info.file.originFileObj.slice(offset, offset + chunkSize)
              );
            });

          mediainfo
            .analyzeData(getSize, readChunk)
            .then((result) => {
              const trackList = result.media && result.media.track;
              const videoInfo =
                trackList &&
                trackList.find((item) => item["@type"] === "Video");
              vm.videoInfo = videoInfo;
            })
            .catch((error) => {
              message.error("获取视频宽高的js加载失败，请稍后再试");
              console.log("error", error);
            });
        });
      }
    },
    deleteVideo() {
      this.videoUrl = "";
      this.scaleValue = 0.5;
      this.showFullVideo = false;
      this.videoInfo = {};
    },
    mouseEnter() {
      if (!this.showMask) this.showMask = true;
    },
    mouseLeave() {
      this.showMask = false;
    },
    scalehandleChange(value) {
      this.scaleValue = value;
    },

    leftPosChange(value) {
      this.videoLeft = value;
      this.videoWidthRadio = 100 - value * 2;
    },
    ONMouseWheel(e) {
      let outsideWrap = this.$refs.outsideWrap;
      outsideWrap.scrollLeft = outsideWrap.scrollLeft - e.deltaY;
    },

    calcRecommandRatio() {
      setTimeout(() => {
        var ratioList = [];
        for (const j of this.simulatorList) {
          const ratioInfo = this.$refs[`simulator${j.id}`][0].getRatio();
          ratioList.push(ratioInfo);
        }
        const minRatio = minBy(ratioList, (o) => o.ratio);
        const maxRatio = maxBy(ratioList, (o) => o.ratio);
        const recommandVideoInfo = {
          width: maxRatio.sizeInfo.videoWrapperWidth,
          height: maxRatio.sizeInfo.videoWrapperHeight,
        };

        var recommandVideoContentInfo = {
          width: Math.ceil(
            (minRatio.sizeInfo.videoWrapperWidth /
              minRatio.sizeInfo.videoWrapperHeight) *
              maxRatio.sizeInfo.videoWrapperHeight
          ),
          height: maxRatio.sizeInfo.videoWrapperHeight,
        };

        if (this.videoInfo.width > this.videoInfo.height) {
          recommandVideoContentInfo = {
            width: maxRatio.sizeInfo.videoWrapperWidth,
            height: Math.ceil(
              (minRatio.sizeInfo.videoWrapperHeight /
                minRatio.sizeInfo.videoWrapperWidth) *
                maxRatio.sizeInfo.videoWrapperWidth
            ),
          };
        }

        this.reRatio = {
          recommandVideoInfo,
          recommandVideoContentInfo,
        };
        console.log("reRatio", this.reRatio);
      }, 100);
    },
  },
  mounted() {
    vm = this;
    this.getImageInfo();
  },
};
</script>

<style lang="less" src="./home.less"></style>
