<template>
  <div class="control-wrapper">
    <div class="play-wrapper" @click="clickPlay">
      <PauseCircleOutlined v-if="isPlay" />
      <PlayCircleOutlined v-else />
    </div>

    <div class="progress-wrapper">
      <div className="progress-bar" @click="clickProgressBar">
        <div
          className="progress"
          :style="{ transform: `translateX(${translateX}%)` }"
        />
      </div>
      <p class="time-text">{{ playTimeText }}</p>
    </div>

    <div class="voice" @click="clickMute">
      <img
        v-show="muted"
        style="width: 16px; cursor: pointer"
        src="@/assets/imgs/mute.png"
      />
      <img
        v-show="!muted"
        style="width: 16px; cursor: pointer"
        src="@/assets/imgs/voice.png"
      />
    </div>
  </div>
</template>

<script>
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons-vue";
export default {
  props: ["index", "duration"],
  components: {
    PlayCircleOutlined,
    PauseCircleOutlined,
  },
  data() {
    return {
      isPlay: true,
      playTime: 0,
      timePercent: 0,
      timer: null,
      voicePercent: 0,
      translateX: -100,
      muted: true,
    };
  },
  computed: {
    playTimeText() {
      const duration = this.duration || 0;
      return Math.floor(this.playTime) + "/" + duration.toFixed(2);
    },
  },
  methods: {
    clickMute() {
      this.muted = !this.muted;
      const videoEle = document.querySelector(".videoItem" + this.index);
      videoEle.muted = this.muted;
    },
    clickPlay() {
      this.isPlay = !this.isPlay;
      const videoEle = document.querySelector(".videoItem" + this.index);
      if (this.isPlay) {
        videoEle.play();
        this.getPlayProgress();
      } else {
        videoEle.pause();
        this.pauseVideoTimer();
      }
    },
    clickProgressBar(e) {
      const delX = e.clientX - e.currentTarget.getBoundingClientRect().x;
      const progressWidth = e.currentTarget.offsetWidth;
      const processPercent = delX / progressWidth;
      const currentTime = processPercent * this.duration;
      const videoEle = document.querySelector(".videoItem" + this.index);
      videoEle.currentTime = currentTime;
      this.getPlayProgress();
    },
    getPlayProgress() {
      this.pauseVideoTimer();
      this.timer = setInterval(() => {
        const videoEle = document.querySelector(".videoItem" + this.index);
        this.playTime = videoEle.currentTime;
        if (!this.duration) {
          this.translateX = -100;
          return;
        }
        this.translateX = -100 + (this.playTime / this.duration) * 100;
      }, 200);
    },
    pauseVideoTimer() {
      clearInterval(this.timer);
    },
    clickVoice(e) {
      console.log("e", e.currentTarget);
    },
  },
  created() {
    this.getPlayProgress();
  },
  unmounted() {
    this.pauseVideoTimer();
  },
};
</script>

<style lang="less">
@flex-center: {
  display: flex;
  align-items: center;
  justify-content: center;
};
.control-wrapper {
  @flex-center();
  .play-wrapper {
    .anticon {
      cursor: pointer;
    }
  }
  .progress-wrapper {
    flex: 1;
    @flex-center();
    margin: 0 6px;
    .progress-bar {
      flex: 1;
      height: 6px;
      border-radius: 3px;
      background: #f8f8f8;
      overflow: hidden;
      cursor: pointer;
      .progress {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        cursor: pointer;
      }
    }
    .time-text {
      width: 60px;
      font-size: 14px;
      text-align: center;
    }
  }

  .ant-progress-steps-outer {
    cursor: pointer;
  }
  .ant-progress {
    margin: 0;
  }
  .voice {
    @flex-center();
    width: 20px;
  }
}
</style>
