<template>
  <section class="simulator-item">
    <div
      class="simulator-wrapper"
      :style="{
        width: item.bg_size.width * scaleValue + 'px',
        height: item.bg_size.height * scaleValue + 'px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }"
    >
      <img
        :style="{
          width: item.bg_size.width * scaleValue + 'px',
        }"
        :src="item.bg_src"
        class="bg_src"
        alt=""
      />
      <div
        class="content-wrapper"
        :style="{
          width: item.content_size.width * scaleValue + 'px',
          height: item.content_size.height * scaleValue + 'px',
          borderRadius: item.no_content_radius ? '0' : '8px',
          display: 'flex',
          flexDirection: 'column',
          overflow: showFullVideo ? '' : 'hidden',
        }"
      >
        <div
          v-if="showFullVideo && JSON.stringify(videoInfo) !== '{}'"
          class="video-cover"
        >
          <img
            v-if="isImage"
            :style="fullSrouceStyle"
            :src="videoUrl"
            style="position: absolute"
          />
          <video
            v-else
            style="position: absolute"
            autoplay="true"
            :style="fullSrouceStyle"
            :class="'videoItem' + index"
            loop
            muted
            :src="videoUrl"
          ></video>
        </div>
        <div
          v-else
          class="video-wrapper"
          :class="`${item.id} ${showFullVideo ? 'full' : ''}`"
        >
          <div
            class="video-box"
            :style="{
              overflow: 'hidden',
              background: '#f8f8f8',
              position: 'relative',
              width: '100%',
              height: '100%',
            }"
          >
            <img
              v-if="isImage"
              :style="{
                objectFit: fitValue,
              }"
              class="video"
              :class="fitValue"
              :src="videoUrl"
            />
            <video
              v-else
              :style="{
                objectFit: fitValue,
              }"
              class="video"
              :class="['videoItem' + index, fitValue]"
              autoplay="true"
              loop
              muted
              :src="videoUrl"
            ></video>
          </div>
        </div>

        <div
          class="bottom-area"
          :style="{
            height: calcTextHeight * scaleValue + 'px',
          }"
          v-if="calcTextHeight"
        >
          <div
            class="mask-area"
            v-if="calcMaskHeight"
            :style="{
              height: calcMaskHeight * scaleValue + 'px',
              top: -calcMaskHeight * scaleValue + 'px',
            }"
          >
            遮罩区域
          </div>
          底部文字与按钮区域
        </div>
      </div>
    </div>

    <p class="radio">
      {{ item.mechine_name }}
      <br />
      分辨率:{{ item.content_size.width }}*{{ item.content_size.height }}
    </p>
    <VideoControl
      v-if="!isImage"
      :index="index"
      :duration="videoInfo.Duration"
    />
  </section>
</template>

<script>
import VideoControl from "@/components/VideoControl/VideoControl.vue";
export default {
  props: [
    "item",
    "index",
    "videoInfo",
    "isImage",
    "videoUrl",
    "designInfo",
    "ratioValue",
    "maskHeight",
    "textHeight",
    "fitValue",
    "scaleValue",
    "showFullVideo",
  ],
  computed: {
    showRadio() {
      var widthRatio = this.item.content_size.width / this.designInfo.width;
      var heightRatio = this.item.content_size.height / this.designInfo.height;
      return this.ratioValue === "width"
        ? widthRatio
        : this.ratioValue === "height"
        ? heightRatio
        : this.ratioValue === 1
        ? 1
        : Math.min(widthRatio, heightRatio);
    },
    // isLongSource() {
    //   return this.videoInfo.height > this.videoInfo.width;
    // },
    calcTextHeight() {
      return Math.ceil(this.showRadio * this.textHeight);
    },
    calcMaskHeight() {
      return Math.ceil(this.showRadio * this.maskHeight);
    },
    fullSrouceStyle() {
      const { videoWrapperWidth, videoWrapperHeight } = this.videoRatio();

      const isLong =
        (this.videoInfo.Width / this.videoInfo.Height) * videoWrapperHeight >
        videoWrapperWidth;

      return isLong
        ? {
            width: "auto",
            height: videoWrapperHeight * this.scaleValue + "px",
            transform: `translateX(-50%)`,
            left: "50%",
          }
        : {
            width: videoWrapperWidth * this.scaleValue + "px",
            height: "auto",
            transform: `translateY(-50%)`,
            top: "50%",
          };
    },
  },
  methods: {
    videoRatio() {
      const videoWrapperWidth = this.item.content_size.width;
      const videoWrapperHeight =
        this.item.content_size.height - this.calcTextHeight;
      const sizeInfo = { videoWrapperWidth, videoWrapperHeight };
      return sizeInfo;
    },
    getRatio() {
      const sizeInfo = this.videoRatio();
      return {
        sizeInfo,
        ratio: sizeInfo.videoWrapperWidth / sizeInfo.videoWrapperHeight,
        id: this.item.id,
      };
    },
  },
  components: {
    VideoControl,
  },
};
</script>
